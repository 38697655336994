import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";

export default function DisculpeMolestias() {
    return (
        <React.Fragment>
            <SEO  title="Disculpe las molestias"/>
            <Layout pageId="apologies">
                <h1>Disculpe las molestias</h1>
                <br></br>
                <br></br>
                <h3>- POR HACER -</h3>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </Layout>
        </React.Fragment>
    );
}
